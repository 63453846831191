<template>
  <div class="mbanner mbanner2">
    <div class="wrapper">
      <div class="mbanner2-tit">园区服务</div>
      <div class="mbanner2-line"></div>
      <div class="mbanner2-en">Park Service</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner2 {
  background-image: url(../../../assets/images/mbanner03.jpg);
}
</style>
