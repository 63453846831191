<template>
  <div class="yqfw">
    <div class="wrapper">
      <ul>
        <li>
          <a @click="toRouter('onestopHall')">
            <div class="yqfw-img"></div>
            <div class="yqfw-info">
              <div class="yqfw-icon">
                <img src="../../../assets/images/icon-yzsdt.png" />
              </div>
              <div class="yqfw-tit">⼀站式⼤厅</div>
              <div class="yqfw-txt">
                以便民、高效、廉洁、规范为宗旨，<br />
                实行一站式办公、一条龙服务、并联式审批、<br />
                阳光下作业、规范化管理的运行模式。
              </div>
              <div class="yqfw-btn">
                <span class="mbtn small">立即进入</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a @click="toRouter('personnel')">
            <div class="yqfw-img"></div>
            <div class="yqfw-info">
              <div class="yqfw-icon">
                <img src="../../../assets/images/icon-gjrcc.png" />
              </div>
              <div class="yqfw-tit">国际⼈才城</div>
              <div class="yqfw-txt">
                国际人才集聚平台、<br />
                全球智力成果转化中心和海内外<br />
                顶级人才创新创业基地。
              </div>
              <div class="yqfw-btn">
                <span class="mbtn small">立即进入</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a @click="toRouter('docking')">
            <div class="yqfw-img"></div>
            <div class="yqfw-info">
              <div class="yqfw-icon">
                <img src="../../../assets/images/icon-gxdj.png" />
              </div>
              <div class="yqfw-tit">供需对接</div>
              <div class="yqfw-txt">
                搭建对接交流平台，<br />
                助力园区机构与区域产业结合，<br />
                实现资源互通。
              </div>
              <div class="yqfw-btn">
                <span class="mbtn small">立即进入</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a @click="toRouter('workstation')">
            <div class="yqfw-img"></div>
            <div class="yqfw-info">
              <div class="yqfw-icon">
                <img src="../../../assets/images/icon-yqgzz.png" />
              </div>
              <div class="yqfw-tit">⼯作站</div>
              <div class="yqfw-txt">
                人力资源服务产业园服务触角延伸，<br />
                区域政府和产业园机构市场化服务于一体的<br />
                人力资源和人才服务综合平台。
              </div>
              <div class="yqfw-btn">
                <span class="mbtn small">立即进入</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a @click="toRouter('financial')">
            <div class="yqfw-img"></div>
            <div class="yqfw-info">
              <div class="yqfw-icon">
                <img src="../../../assets/images/icon-rmtzx.png" />
              </div>
              <div class="yqfw-tit">融媒体中⼼</div>
              <div class="yqfw-txt">
                搭建对接交流平台，<br />
                助力园区机构与区域产业结合，<br />
                实现资源互通。
              </div>
              <div class="yqfw-btn">
                <span class="mbtn small">立即进入</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
   
  },
  methods: {
    toRouter(param) {
      this.$router.push({ name: param });
    },
   
  },
};
</script>
<style scoped lang="scss">
.wrapper li:nth-child(1) .yqfw-img {
  background-image: url(../../../assets/images/img10.png);
}
.wrapper li:nth-of-type(2) .yqfw-img {
  background-image: url(../../../assets/images/img11.png);
}
.wrapper li:nth-of-type(3) .yqfw-img {
  background-image: url(../../../assets/images/img12.png);
}
.wrapper li:nth-of-type(4) .yqfw-img {
  background-image: url(../../../assets/images/img13.png);
}
.wrapper li:nth-of-type(5) .yqfw-img {
  background-image: url(../../../assets/images/img14.png);
}
</style>
